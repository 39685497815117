<template>
  <div class="home">
    <Header></Header>
    <About></About>
    <Pricing></Pricing>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import Component from 'vue-class-component'
import Header from '@/components/Header.vue'
import About from '@/components/About.vue'
import Pricing from '@/components/Pricing.vue'
import Footer from '@/components/Footer.vue'

@Component({
  // Specify `components` option.
  // See Vue.js docs for all available options:
  // https://vuejs.org/v2/api/#Options-Data
  components: {
    Footer,
    Header,
    About,
    Pricing
  }
})
export default class Home extends Vue {
}
</script>
<style lang="scss">

//.up-shortcut{
//  cursor: pointer;
//  position: fixed;
//  color: white;
//  top: 10%;
//  right: 50px;
//  z-index: 9999;
//}
//
//.down-shortcut{
//  cursor: pointer;
//  position: fixed;
//  bottom: 10%;
//  right: 50px;
//  z-index: 9999;
//  color: white;
//}

.home{
  background-color: #1c1c1c;
}

</style>
