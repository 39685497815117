











































import Vue from 'vue'
import Component from 'vue-class-component'

// Define the component in class-style
@Component
export default class About extends Vue {
  // Class properties will be component data

  showAnimation = false
  showAboutContainerAnimation = false

  isHrVisible (isVisible: boolean, entry: any): void {
    if (!this.showAnimation) {
      this.showAnimation = entry.isIntersecting
    }
  }

  isAboutContainerVisible (isVisible: boolean, entry: any): void {
    if (!this.showAboutContainerAnimation) {
      this.showAboutContainerAnimation = entry.isIntersecting
    }
  }
}
