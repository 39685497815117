










































































import Vue from 'vue'
import Component from 'vue-class-component'
import { EventBus } from '@/main'

// Define the component in class-style
@Component
export default class Pricing extends Vue {
  // Class properties will be component data
  pricingList = [
    {
      sluzba: 'Buzz Combo',
      cena: '26 €',
      dobatrvania: '60 min',
      popis: 'Klasický strih/fade/crop, úprava brady, umytie vlasov, úprava obočia, depilácia voskom, záverečny styling.'
    },
    {
      sluzba: 'Strih Fade/Crop',
      cena: '17 €',
      dobatrvania: '45 min',
      popis: 'Strihanie vlasov rôznymi technikami, úprava kontúr, umytie vlasov, záverečny styling.'
    },
    {
      sluzba: 'Klasický pánsky strih',
      cena: '13 €',
      dobatrvania: '30 min',
      popis: 'Strihanie vlasov nožnicami/strojčekom, úprava kontúr, umytie vlasov, záverečny styling.'
    },
    {
      sluzba: 'Úprava brady',
      cena: '12 €',
      dobatrvania: '30 min',
      popis: 'Úprava brady strojčekom/nožnicami, holenie britvou, horúci zábal/naparovanie.'
    },
    {
      sluzba: 'Holenie hlavy',
      cena: '15 €',
      dobatrvania: '45 min',
      popis: 'Horúci zábal/naparovanie, holenie hlavy strojčekom/britvou, umytie hlavy.'
    },
    {
      sluzba: 'Buzz Junior (deti od 6 do 12 rokov)',
      cena: '13 €',
      dobatrvania: '45 min',
      popis: 'Strihanie vlasov nožnicami/strojčekom, umytie vlasov, záverečny styling.'
    },
    {
      sluzba: 'Pánske braids',
      cena: 'od 20 €',
      dobatrvania: '120 min',
      popis: ''
    }
  ]

  additionalService = [
    { sluzba: 'Maska na tvár', cena: '3 €' },
    { sluzba: 'Depilácia voskom', cena: '3 €' }
  ]

  additionalFields = [{ key: 'sluzba', label: 'Doplnkové služby', thClass: 'col-7' },
    { key: 'cena', label: 'Cena', thClass: 'col-3' },
    { key: 'showDetail', label: '', thClass: 'col-3' }
  ]

  fields = [{ key: 'sluzba', label: 'Služba', thClass: 'col-7' },
    { key: 'cena', label: 'Cena', thClass: 'col-3' },
    { key: 'showDetail', label: '', thClass: 'col-3' }
  ]

  showAnimation = false
  showMainReservation = false

  fixed = true

  isHrVisible (isVisible: boolean, entry: any): void {
    if (!this.showAnimation) {
      this.showAnimation = entry.isIntersecting
    }
  }

  created () {
    EventBus.$on('goToReservation', this.reservationHander)
  }

  detailOnClick (row: any): void {
    row.toggleDetails()
  }

  showMainReservationHandler () {
    this.showMainReservation = !this.showMainReservation
  }

  reservationHander (event: any):void{
    this.showMainReservation = true
    const el = this.$el.getElementsByClassName('scrollToMe')[0]
    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
