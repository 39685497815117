import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/main.scss'
import VueObserveVisibility from 'vue-observe-visibility'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleChevronUp, faChevronCircleDown, faCircleDown, faArrowDown, faMapLocationDot, faPhone, faUsers, faClock, faLocationDot, faAddressBook, faEnvelopeOpenText, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGooglePay, faFacebook, faInstagram, faCcVisa, faCcMastercard, faCcAmex, faApplePay, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

Vue.use(BootstrapVue)
Vue.use(VueObserveVisibility)
// importnuta direktiva pre ikonky
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
// pridavanie ikoniek sem
library.add(faFacebook, faInstagram, faCircleChevronUp, faChevronCircleDown, faCircleDown, faArrowDown, faClock, faMapLocationDot, faPhone, faUsers, faLocationDot, faAddressBook, faEnvelopeOpenText, faCreditCard, faCcVisa, faCcMastercard, faCcAmex, faApplePay, faGooglePay, faWhatsapp)

export const EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
