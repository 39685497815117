



















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { EventBus } from '@/main'

// Define the component in class-style
@Component
export default class Header extends Vue {
  // Class properties will be component data
  interval = 5000

  goToReservation ():void{
    EventBus.$emit('goToReservation', true)
  }
}
