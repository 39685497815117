































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

// Define the component in class-style
@Component
export default class Footer extends Vue {
  // Class properties will be component data
  showFirstCard = false
  showSecondCard = false
  showThirdCard = false
  showFourthCard = false

  isFirstCardVisible (isVisible: boolean, entry: any): void {
    if (!this.showFirstCard) {
      this.showFirstCard = entry.isIntersecting
    }
  }

  isSecondCardVisible (isVisible: boolean, entry: any): void {
    if (!this.showSecondCard) {
      this.showSecondCard = entry.isIntersecting
    }
  }

  isThirdCardVisible (isVisible: boolean, entry: any): void {
    if (!this.showThirdCard) {
      this.showThirdCard = entry.isIntersecting
    }
  }

  isFourthCardVisible (isVisible: boolean, entry: any): void {
    if (!this.showFourthCard) {
      this.showFourthCard = entry.isIntersecting
    }
  }
}
